exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-insights-tsx": () => import("./../../../src/pages/insights.tsx" /* webpackChunkName: "component---src-pages-insights-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-process-tsx": () => import("./../../../src/pages/process.tsx" /* webpackChunkName: "component---src-pages-process-tsx" */),
  "component---src-pages-services-ts": () => import("./../../../src/pages/services.ts" /* webpackChunkName: "component---src-pages-services-ts" */),
  "component---src-pages-work-ts": () => import("./../../../src/pages/work.ts" /* webpackChunkName: "component---src-pages-work-ts" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/BlogPost.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-case-study-tsx": () => import("./../../../src/templates/CaseStudy.tsx" /* webpackChunkName: "component---src-templates-case-study-tsx" */),
  "component---src-templates-company-service-tsx": () => import("./../../../src/templates/CompanyService.tsx" /* webpackChunkName: "component---src-templates-company-service-tsx" */),
  "component---src-templates-insights-category-tsx": () => import("./../../../src/templates/InsightsCategory.tsx" /* webpackChunkName: "component---src-templates-insights-category-tsx" */)
}

